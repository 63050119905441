import React from "react";
import { Row } from "react-bootstrap";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";

const PrivacyTemplate = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Row className="single-page">
        <div className="entry-content">
          <h1>{t("privacyPage.title")}</h1>
          <p>{t("privacyPage.paragraph")}</p>
          <h4>{t("privacyPage.entryContent.titleOne")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("privacyPage.entryContent.paragraphOne")
            }}
          ></ol>
          <h4>{t("privacyPage.entryContent.titleTwo")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("privacyPage.entryContent.paragraphTwo")
            }}
          ></ol>
          <h4>{t("privacyPage.entryContent.titleThree")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("privacyPage.entryContent.paragraphThree")
            }}
          ></ol>
          <h4>{t("privacyPage.entryContent.titleFour")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("privacyPage.entryContent.paragraphFour")
            }}
          ></ol>
          <h4>{t("privacyPage.entryContent.titleFive")}</h4>
          <p>{t("privacyPage.entryContent.textFive")}</p>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("privacyPage.entryContent.paragraphFive")
            }}
          ></ol>
          <h4>{t("privacyPage.entryContent.titleSix")}</h4>
          <p>{t("privacyPage.entryContent.paragraphSix")}</p>
          <h4>{t("privacyPage.entryContent.titleSeven")}</h4>
          <ol
            dangerouslySetInnerHTML={{
              __html: t("privacyPage.entryContent.paragraphSeven")
            }}
          ></ol>
          <h4>{t("privacyPage.entryContent.titleEight")}</h4>
          <p>{t("privacyPage.entryContent.paragraphEight")}</p>
        </div>
      </Row>
    </Layout>
  );
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default PrivacyTemplate;
